import styled from '@emotion/styled';

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2px;
  padding: 8px 0 7px 0;

  ${(props) => props.theme.breakpoints.down('lt_1024')} {
    padding: 2px 0 3px 0;
  }
`;

export const TitleTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  ${(props) => props.theme.breakpoints.down('dt_1440')} {
    gap: 4px;
  }

  ${(props) => props.theme.breakpoints.down('st_600')} {
    gap: 2px;
  }
`;

export const Text = styled.p`
  color: #313135;
  font-size: 22px;
  line-height: 150%;
  font-weight: 700;

  ${(props) => props.theme.breakpoints.down('dt_1440')} {
    font-size: 21px;
  }

  ${(props) => props.theme.breakpoints.down('lt_1024')} {
    font-size: 18px;
  }
`;
