import React, { CSSProperties, ReactEventHandler } from 'react';
import Image, { ImageLoader } from 'next/image';

type CustomImageProps = {
  src: string;
  alt?: string;
  fill?: boolean;
  quality?: number;
  isUnoptimized?: boolean;
  style?: CSSProperties;
  sizes?: string;
  priority?: boolean;
  watermark?: boolean;
  onLoad?: ReactEventHandler<HTMLImageElement>;
  placeholder?: 'blur' | 'empty';
  width?: number;
  height?: number;
  onClick?: ReactEventHandler<HTMLImageElement>;
  className?: string;
  objectFit?: CSSProperties['objectFit'];
};

/**
 * 상대경로에서 가져오는 asset들을 image optimizer에게 전달하기 위해 절대경로로 변환
 * @param src
 */
const pathAbsolutifier = (src) => (src[0] === '/' ? 'https://www.acon3d.com' + src : src);
const imageLoader: ImageLoader = ({ src, width, quality }) => {
  return `${process.env.NEXT_PUBLIC_IMAGEOPTIMIZER}/?image=${encodeURIComponent(pathAbsolutifier(src))}&width=${width}&quality=${quality || 85}`;
};

const imageLoaderWithWatermark: ImageLoader = (arg) => {
  return imageLoader(arg) + '&watermark=true';
};

export function CustomImage({
  src,
  isUnoptimized = false,
  fill = true,
  alt,
  style = {},
  sizes,
  watermark,
  objectFit,
  placeholder = 'blur',
  className,
  priority = false,
  ...rest
}: CustomImageProps) {
  // gif
  // imageOptimizer가 gif 변환을 지원하지 않음
  const unoptimized = isUnoptimized || src.endsWith('gif');

  return (
    <Image
      src={src}
      fill={fill}
      sizes={sizes}
      style={{ objectFit, ...style }}
      unoptimized={unoptimized}
      loader={watermark ? imageLoaderWithWatermark : imageLoader}
      placeholder={placeholder}
      blurDataURL={'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mO8vAkAAl4Bh+J6dBIAAAAASUVORK5CYII='}
      alt={alt}
      className={'custom-img' + (className ? ' ' + className : '')}
      priority={priority}
      {...rest}
    />
  );
}
