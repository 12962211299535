import styled from '@emotion/styled';
import LeftAndRightButton from '@components/_new_components/Common/Button/LeftAndRightButton';
import { Skeleton } from '@components/_new_components/Common/Skeleton/Skeleton.styles';
import ErrorHandlingRefetch from '@components/_new_components/Common/error/ErrorHandlingRefetch';

export const ProductCardSliderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  ${(props) => props.theme.breakpoints.down('st_600')} {
    gap: 8px;
  }
`;

export const ProductCardsBox = styled.div`
  width: 100%;
  overflow: hidden;

  ${(props) => props.theme.breakpoints.down('dt_1440')} {
    width: calc(100% + (var(--content-side-padding) * 2px));
    left: calc(var(--content-side-padding) * -1px);
    position: relative;
  }
`;

export const ProductCards = styled.div<{ slideIndex: number }>`
  width: 100%;
  display: flex;
  gap: 20px;
  position: relative;
  transition: left 0.2s;
  left: 0;

  // Hide scrollbar
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  ${(props) => props.theme.breakpoints.down('dt_1720')} {
    gap: 16px;
  }

  ${(props) => props.theme.breakpoints.down('st_600')} {
    gap: 12px;
  }

  ${(props) => props.slideIndex && `left: calc(-${props.slideIndex * 20}% - ${props.slideIndex * 4}px);`};

  @media (min-width: 1440px) and (max-width: 2064px) {
    ${(props) => props.slideIndex && `left: calc(-${props.slideIndex * 25}% - ${props.slideIndex * 4}px);`};
  }

  ${(props) => props.theme.breakpoints.down('dt_1440')} {
    overflow-x: auto;
    padding-left: calc(var(--content-side-padding) * 1px);
    padding-right: calc(var(--content-side-padding) * 1px);
  }

  & > div {
    width: 279.5px;

    @media (min-width: 2065px) {
      min-width: calc(20% - 16px);
      width: calc(20% - 16px);
    }

    @media (min-width: 1720px) and (max-width: 2064px) {
      min-width: calc(25% - 16px);
      width: calc(25% - 16px);
    }

    @media (min-width: 1440px) and (max-width: 1719px) {
      min-width: calc(25% - 12px);
      width: calc(25% - 12px);
    }

    ${(props) => props.theme.breakpoints.down('st_600')} {
      min-width: 241px;
      width: 241px;
    }
  }
`;

export const ProductCardSliderButtons = styled(LeftAndRightButton)`
  padding-right: 4px;

  ${(props) => props.theme.breakpoints.down('dt_1440')} {
    display: none;
  }
`;

export const ProductCardSliderSkeleton = styled.div`
  min-width: 279.5px;
  height: auto;

  @media (min-width: 2065px) {
    min-width: calc(20% - 16px);
  }

  @media (min-width: 1720px) and (max-width: 2064px) {
    min-width: calc(25% - 16px);
  }

  @media (min-width: 1440px) and (max-width: 1719px) {
    min-width: calc(25% - 12px);
  }

  ${(props) => props.theme.breakpoints.down('st_600')} {
    min-width: 241px;
  }
`;

export const ProductCardSliderImageSkeleton = styled(Skeleton)`
  aspect-ratio: 350/215;
  height: auto;
`;

export const MainWidgetErrorBox = styled(ErrorHandlingRefetch)`
  min-height: 382px;

  ${(props) => props.theme.breakpoints.down('dt_2560')} {
    min-height: 336px;
  }

  ${(props) => props.theme.breakpoints.down('dt_2184')} {
    min-height: 321px;
  }

  ${(props) => props.theme.breakpoints.down('dt_1720')} {
    min-height: 280px;
  }

  ${(props) => props.theme.breakpoints.down('dt_1440')} {
    min-height: 293px;
  }

  ${(props) => props.theme.breakpoints.down('st_600')} {
    min-height: 259px;
  }
`;
