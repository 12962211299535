import dynamic from 'next/dynamic';
import { SubBannerSkeleton } from '@components/_new_components/Pages/main/banner/SubBanner/SubBanner.styles';
import { IBannerDetail, IMainDetail, ISubDetail } from '@api/banner/banner.types';
import { filterNow } from '@components/_new_components/Pages/main/banner/utils';
import { MainBannerWrapper, SlideBannerSkeleton } from './banner.styles';

const MainSlideBanner = dynamic(() => import('@components/_new_components/Pages/main/banner/MainSlideBanner/MainSlideBanner'), {
  ssr: false,
  loading: () => <SlideBannerSkeleton />,
});
const SubBanner = dynamic(() => import('@components/_new_components/Pages/main/banner/SubBanner/SubBanner'), {
  ssr: false,
  loading: () => <SubBannerSkeleton />,
});

interface Props {
  data?: Record<any, Array<IBannerDetail>>;
  isLoading: boolean;
  error?: any;
}

export default function MainBanners({ data, isLoading, error }: Props) {
  const mainBannerData = filterNow<IMainDetail>(data?.main || []) || [];
  const subBannerData = filterNow<ISubDetail>(data?.sub || []) || [];

  return (
    <MainBannerWrapper className={'mainBannerWrapper'}>
      {mainBannerData.length === 0 || isLoading || error ? <SlideBannerSkeleton /> : <MainSlideBanner data={mainBannerData} />}
      {subBannerData.length === 0 || isLoading || error ? <SubBannerSkeleton /> : <SubBanner data={subBannerData[0]} />}
    </MainBannerWrapper>
  );
}
