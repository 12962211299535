import styled from '@emotion/styled';

export const ErrorHandlingReloadWrapper = styled.div`
  background-color: #F5F5F5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 16px;
  border-radius: 16px;
  width: 100%;
  min-height: 259px;

  ${(props) => props.theme.breakpoints.down('lt_1024')} {
    row-gap: 12px;
  }
`;

export const ErrorMessageText = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #313135;
  text-align: center;

  ${(props) => props.theme.breakpoints.down('lt_1024')} {
    font-size: 15px;
  }

  ${(props) => props.theme.breakpoints.down('lt_1024')} {
    font-size: 14px;
  }

  ${(props) => props.theme.breakpoints.down('st_600')} {
    white-space: pre-wrap;
  }
`;

export const RefetchButton = styled.div`
  // button styles
  border-radius: 4px;
  border: 1px solid #D2D2D3;
  background: #FFF;
  display: flex;
  padding: 8px 14px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  
  // font styles
  font-size: 14px;
  font-weight: 600;
  line-height: 160%;
  text-transform: uppercase;

  ${(props) => props.theme.breakpoints.down('lt_1024')} {
    font-size: 13px;
  }
`;
