import styled from '@emotion/styled';

export const MainWidgetWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 50px;

  ${(props) => props.theme.breakpoints.down('dt_1720')} {
    gap: 40px;
  }

  ${(props) => props.theme.breakpoints.down('dt_1440')} {
    gap: 34px;
  }

  ${(props) => props.theme.breakpoints.down('lt_1024')} {
    gap: 32px;
  }

  ${(props) => props.theme.breakpoints.down('st_600')} {
    gap: 24px;
  }
`;
