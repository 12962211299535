import styled from '@emotion/styled';
import { Skeleton } from '@components/_new_components/Common/Skeleton/Skeleton.styles';

export const SubBannerWrapper = styled.div(({ theme }) => {
  const { breakpoints } = theme;
  return {
    // layout 개발용 mock style
    position: 'relative',
    backgroundColor: '#f5f5f5',
    minWidth: '706px',
    borderRadius: '16px',
    overflow: 'hidden',
    height: '364px',

    [breakpoints.down('dt_2560')]: {
      minWidth: '584px',
    },

    [breakpoints.down('dt_2184')]: {
      minWidth: '432px',
    },

    [breakpoints.down('dt_1720')]: {
      minWidth: '344px',
      height: '290px',
    },

    [breakpoints.down('dt_1440')]: {
      display: 'none',
    },
  };
});

export const SubBannerSkeleton = styled(Skeleton)`
  min-width: 706px;

  ${(props) => props.theme.breakpoints.down('dt_1720')} {
    min-width: 344px;
  }

  ${(props) => props.theme.breakpoints.down('dt_1720')} {
    min-width: 432px;
  }
`;
