import React from 'react';
import Link from 'next/link';
import { buttonClickLog } from '@lib/mixpanel/events';
import ChevronRightIcon from '@public/icons/main/ChevronRightIcon';
import { Text, TitleTextWrapper, TitleWrapper } from './Title.styles';

interface Props {
  link?: string;
  rightComponent?: React.ReactNode;
  children: React.ReactNode;
  clickMixpanelActionName?: string;
}

export default function Title({ link, rightComponent, clickMixpanelActionName, children }: Props) {
  const handleTitleClick = () => {
    if (clickMixpanelActionName) buttonClickLog({ actionName: clickMixpanelActionName });
  };

  return (
    <TitleWrapper onClick={handleTitleClick}>
      {link ? (
        <Link href={link}>
          <TitleTextWrapper>
            <Text>{children}</Text>

            <ChevronRightIcon />
          </TitleTextWrapper>
        </Link>
      ) : (
        <Text>{children}</Text>
      )}

      {rightComponent}
    </TitleWrapper>
  );
}
