import React from 'react';
import { useWindowSize } from 'react-use';
import { useTranslation } from 'next-i18next';
import {
  MainWidgetErrorBox,
  ProductCards,
  ProductCardsBox,
  ProductCardSliderButtons,
  ProductCardSliderContainer,
  ProductCardSliderImageSkeleton,
  ProductCardSliderSkeleton,
} from '@components/_new_components/Pages/main/ProductCardSlider/ProductCardSlider.styles';
import ProductCard from '@components/_new_components/Common/ProductCard/ProductCard';
import Title from '@components/_new_components/Pages/main/Title';
import { productClickEventLog } from '@lib/mixpanel/events';
import { TActionName } from '@components/common/Card/type';
import { Skeleton } from '@components/_new_components/Common/Skeleton/Skeleton.styles';
import { IProductCardSliderProps } from '@components/_new_components/Pages/main/ProductCardSlider/ProductCardSlider.types';

export default function ProductCardSlider({ products, isLoading, title, viewMoreLink, clickMixpanelActionName, handleError }: IProductCardSliderProps) {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const [sliderIndex, setSliderIndex] = React.useState(0);
  // 2065 사이즈 미만에서 상품 카드 4개, 이상은 5개 표시
  const isUnder2065 = width < 2065;
  const isUnder1440 = width < 1440;
  let maxSliderIndex = products.length > 0 ? Math.ceil(products.length - (isUnder2065 ? 4 : 5)) : 0;
  maxSliderIndex = maxSliderIndex < 0 ? 0 : maxSliderIndex;

  const handleSliderButtonClick = (type: 'prev' | 'next') => {
    return () => {
      if (type === 'prev' && sliderIndex === 0) return;
      if (type === 'next' && sliderIndex === maxSliderIndex) return;
      setSliderIndex((prev) => (type === 'prev' ? prev - 1 : prev + 1));
    };
  };

  const handleProductCardClick = (idx: number) => {
    return (actionName: TActionName, productTitle: string, productId: number) => {
      productClickEventLog({
        pageName: 'main',
        actionName: actionName,
        productName: productTitle,
        productNo: productId.toString(),
        groupSno: null,
        groupTitle: title,
        order: idx + 1,
      });
    };
  };

  React.useEffect(() => {
    setSliderIndex(0);
  }, [isUnder2065, isUnder1440]);

  if (!isLoading && !handleError.isError && products.length === 0) return <></>;

  return (
    <ProductCardSliderContainer>
      <Title
        link={viewMoreLink}
        clickMixpanelActionName={clickMixpanelActionName}
        rightComponent={
          <ProductCardSliderButtons
            isDisabledLeftButton={sliderIndex === 0}
            isDisabledRightButton={sliderIndex === maxSliderIndex}
            onClickLeftButton={handleSliderButtonClick('prev')}
            onClickRightButton={handleSliderButtonClick('next')}
          />
        }
      >
        {title}
      </Title>

      <ProductCardsBox>
        {!handleError.isError && (
          <ProductCards slideIndex={sliderIndex}>
            {isLoading &&
              new Array(5).fill(null).map((_, idx) => (
                <ProductCardSliderSkeleton key={idx}>
                  <ProductCardSliderImageSkeleton />
                  <Skeleton width={'90%'} height={'20px'} marginTop={10} />
                  <Skeleton width={'95%'} height={'40px'} marginTop={10} />
                  <Skeleton width={'30%'} height={'30px'} marginTop={10} />
                </ProductCardSliderSkeleton>
              ))}

            {products.map((product, idx) => (
              <ProductCard
                key={`${product.id}_${idx}`}
                product={product}
                productCardImageSizes={'(max-width: 300px) 100vw, (max-width: 599px) 50vw, (max-width: 1440px) 33vw, 20vw'}
                onClickProductCard={handleProductCardClick(idx)}
              />
            ))}
          </ProductCards>
        )}
      </ProductCardsBox>

      {/** [todo] 번역문 전달받으면 반영 예정 */}
      {handleError.isError && (
        <MainWidgetErrorBox message={t('main:error.message')} refetchButtonText={t('main:error.reload')} onClickRefetchButton={() => handleError.refetch()} />
      )}
    </ProductCardSliderContainer>
  );
}
