import styled from '@emotion/styled';

export const LeftAndRightButtonContainer = styled.div`
  display: flex;
`;

const ButtonStyle = styled.div<{ isDisabled }>`
  display: flex;
  max-height: 22px;
  padding: 2.5px;
  justify-content: center;
  align-items: center;
  border: 1px solid #e7e7e8;
  background: #fff;
  cursor: pointer;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  ${(props) =>
    props.isDisabled &&
    `
    cursor: not-allowed;
  `}
`;

export const LeftButtonWrapper = styled(ButtonStyle)`
  border-radius: 4px 0 0 4px;
  border-right: 0;
`;

export const RightButtonWrapper = styled(ButtonStyle)`
  border-radius: 0 4px 4px 0;
`;
