import React from 'react';
import styled from '@emotion/styled';

const ChevronRightIconWrapper = styled.div`
  & > .small,
  & > .middle {
    display: none;
  }

  ${(props) => props.theme.breakpoints.down('dt_1440')} {
    & > .large,
    & > .small {
      display: none;
    }

    & > .middle {
      display: block;
    }
  }

  ${(props) => props.theme.breakpoints.down('lt_1024')} {
    & > .large,
    & > .middle {
      display: none;
    }

    & > .small {
      display: block;
    }
  }
`;

export default function ChevronRightIcon() {
  return (
    <ChevronRightIconWrapper>
      <div className={'large'}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
          <path d="M8.5 5.5L15.5 12.5L8.5 19.5" stroke="black" strokeWidth="2.35" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </div>

      <div className={'middle'}>
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none">
          <path d="M7.79102 5.08203L14.2077 11.4987L7.79102 17.9154" stroke="black" strokeWidth="2.15417" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </div>

      <div className={'small'}>
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
          <path d="M6.375 4.25L11.625 9.5L6.375 14.75" stroke="black" strokeWidth="1.7625" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </div>
    </ChevronRightIconWrapper>
  );
}
