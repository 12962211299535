import React from 'react';
import { IErrorHandlingRefetchProps } from '@components/_new_components/Common/error/ErrorHandlingRefetch.types';
import {
    ErrorHandlingReloadWrapper,
    ErrorMessageText, RefetchButton,
} from '@components/_new_components/Common/error/ErrorHandlingRefetch.styles';

export default function ErrorHandlingRefetch({ message, refetchButtonText, onClickRefetchButton, ...rest }: IErrorHandlingRefetchProps) {
    return (
        <ErrorHandlingReloadWrapper {...rest}>
            <ErrorMessageText>{message}</ErrorMessageText>
            <RefetchButton onClick={onClickRefetchButton}>{refetchButtonText}</RefetchButton>
        </ErrorHandlingReloadWrapper>
    );
}
