import React from 'react';
import { LeftAndRightButtonContainer, LeftButtonWrapper, RightButtonWrapper } from '@components/_new_components/Common/Button/LeftAndRightButton.styles';

interface Props {
  onClickLeftButton?: () => void;
  onClickRightButton?: () => void;
  isDisabledLeftButton?: boolean;
  isDisabledRightButton?: boolean;
}

export default function LeftAndRightButton({ isDisabledLeftButton, isDisabledRightButton, onClickLeftButton, onClickRightButton, ...rest }: Props) {
  return (
    <LeftAndRightButtonContainer {...rest}>
      <LeftButtonWrapper onClick={onClickLeftButton} isDisabled={isDisabledLeftButton}>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            opacity={!isDisabledLeftButton ? '1' : '0.3'}
            d="M9.33411 3.33467C9.48988 3.33437 9.64084 3.38862 9.76078 3.48801C9.89712 3.60104 9.98288 3.76367 9.99914 3.94003C10.0154 4.11638 9.96082 4.29195 9.84745 4.42801L6.86078 8.00134L9.74078 11.5813C9.85262 11.7191 9.90496 11.8957 9.88619 12.0721C9.86742 12.2486 9.7791 12.4102 9.64078 12.5213C9.50133 12.644 9.31703 12.703 9.13226 12.684C8.9475 12.665 8.77903 12.5698 8.66744 12.4213L5.44745 8.42134C5.24523 8.17534 5.24523 7.82067 5.44745 7.57467L8.78078 3.57467C8.91642 3.41104 9.12196 3.3219 9.33411 3.33467Z"
            fill="#313135"
            stroke="#F5F5F6"
            strokeWidth="0.363636"
          />
        </svg>
      </LeftButtonWrapper>
      <RightButtonWrapper onClick={onClickRightButton} isDisabled={isDisabledRightButton}>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            opacity={!isDisabledRightButton ? '1' : '0.3'}
            d="M6.66589 12.6653C6.51012 12.6656 6.35916 12.6114 6.23922 12.512C6.10288 12.399 6.01712 12.2363 6.00086 12.06C5.9846 11.8836 6.03918 11.708 6.15256 11.572L9.13922 7.99866L6.25922 4.41866C6.14738 4.28093 6.09504 4.1043 6.11381 3.92787C6.13258 3.75145 6.2209 3.58978 6.35922 3.47866C6.49867 3.35597 6.68297 3.29701 6.86774 3.31599C7.0525 3.33498 7.22097 3.43018 7.33256 3.57866L10.5526 7.57866C10.7548 7.82466 10.7548 8.17933 10.5526 8.42533L7.21922 12.4253C7.08358 12.589 6.87805 12.6781 6.66589 12.6653Z"
            fill="#313135"
            stroke="#F5F5F6"
            strokeWidth="0.363636"
          />
        </svg>
      </RightButtonWrapper>
    </LeftAndRightButtonContainer>
  );
}
