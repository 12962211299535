import styled from '@emotion/styled';

export const MainContents = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 64px;

  ${(props) => props.theme.breakpoints.down('dt_2184')} {
    gap: 48px;
  }

  ${(props) => props.theme.breakpoints.down('dt_1720')} {
    gap: 40px;
  }

  ${(props) => props.theme.breakpoints.down('dt_1440')} {
    gap: 32px;
  }

  ${(props) => props.theme.breakpoints.down('lt_1024')} {
    gap: 30px;
  }

  ${(props) => props.theme.breakpoints.down('st_600')} {
    gap: 20px;
  }
`;

export const MainPromotionAndInfoSectionWrapper = styled.div`
  display: flex;
  column-gap: 32px;
  row-gap: 64px;
  flex-wrap: wrap;

  ${(props) => props.theme.breakpoints.down('dt_1720')} {
    column-gap: 24px;
    row-gap: 56px;
  }

  ${(props) => props.theme.breakpoints.down('dt_1440')} {
    column-gap: 20px;
    row-gap: 48px;
  }

  ${(props) => props.theme.breakpoints.down('lt_1024')} {
    column-gap: 16px;
    row-gap: 46px;
  }

  ${(props) => props.theme.breakpoints.down('st_600')} {
    row-gap: 36px;
  }
`;
