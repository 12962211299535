import styled from '@emotion/styled';
import { Skeleton } from '@components/_new_components/Common/Skeleton/Skeleton.styles';

export const MainBannerWrapper = styled.div(({ theme }) => {
  const { breakpoints } = theme;
  return {
    [breakpoints.up('dt_1440')]: {
      display: 'grid',
      gap: '24px',
      gridTemplateColumns: '2.1fr 1fr',
    },
    [breakpoints.up('dt_1720')]: {
      display: 'grid',
      gap: '32px',
      gridTemplateColumns: '2.1fr 1fr',
    },

    [breakpoints.down('dt_2184')]: {
      paddingBottom: '16px',
    },

    [breakpoints.down('dt_1720')]: {
      paddingBottom: '16px',
    },

    [breakpoints.down('lt_1024')]: {
      paddingBottom: '8px',
    },

    [breakpoints.down('st_600')]: {
      paddingBottom: '14px',
    },
  };
});

// [todo]: height 변경해야 함.
export const SlideBannerSkeleton = styled(Skeleton)`
  width: 100%;
  height: 364px;

  ${(props) => props.theme.breakpoints.down('dt_1720')} {
    height: 290px;
  }

  ${(props) => props.theme.breakpoints.down('dt_1440')} {
    height: 314px;
  }

  ${(props) => props.theme.breakpoints.down('lt_1024')} {
    height: 243px;
  }

  ${(props) => props.theme.breakpoints.down('st_600')} {
    margin-top: -16px;
    margin-left: -16px;
    margin-right: -16px;
    width: 100vw;
    height: 156px;
  }
`;
